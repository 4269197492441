import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getMenu } from '../services/getMenu';
import { ImageLink } from '../constants';

const Header = ({ settingData, socialData }) => {
    const [menuData, setMenuData] = useState([]);
    const location = useLocation();
    const pathname = location.pathname;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openSubmenus, setOpenSubmenus] = useState({});

    const toggleSubmenu = (menuId) => {
        setOpenSubmenus(prevState => ({
            ...prevState,
            [menuId]: !prevState[menuId] 
        }));
    };
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        document.body.classList.toggle('mobile-menu-visible', !isMenuOpen);
    };
    const fetchMenuData = async () => {
        const response = await getMenu();
        setMenuData(response)
    };

    useEffect(() => {
        fetchMenuData();
    }, [])

    const isHomePage = pathname === '/';

    return (
        <>
            <header>
                <div
                    id="sticky-header"
                    className={`menu-area menu-style-three ${isHomePage ? 'transparent-header' : 'dark-header'}`}
                >
                    <div className="container custom-container">
                        <div className="row">
                            <div className="col-12" >
                                <div className="mobile-nav-toggler" onClick={toggleMenu}>
                                    <img loading="auto" src="/assets/img/menu.png" alt='menu_img' />
                                </div>
                                <div className="menu-wrap" >
                                    <nav className="menu-nav">
                                        <div className="row g-0 align-items-center">
                                            {settingData.logo && <div className="col-lg-2">
                                                <div className="logo">
                                                    <Link to="/">
                                                        <img loading="auto" src={ImageLink(settingData.logo)} alt='logo_img' />
                                                    </Link>
                                                </div>
                                            </div>}
                                            <div className="col-lg-10">
                                                <div className="navbar-wrap main-menu d-none d-lg-flex">
                                                    <ul className="navigation">
                                                        {menuData.map((item, id) => {
                                                            return (
                                                                <React.Fragment key={id}>
                                                                    <li className={`${item.subMenus.length > 0 && 'menu-item-has-children'}`}>
                                                                        <Link to={!item.subMenus.length > 0 && `/${item.menuUrl}`}>
                                                                            {item.menuName}
                                                                            {item.subMenus.length > 0 && <i className="fas fa-chevron-down" />}
                                                                        </Link>

                                                                        {item.subMenus.length > 0 && (
                                                                            <ul className="sub-menu">
                                                                                {item.subMenus.map((subMenuItem, subIndex) => {
                                                                                    return (
                                                                                        <li key={subIndex}>
                                                                                            <Link
                                                                                                to={`/${item.menuUrl}/${subMenuItem.menuUrl}`}
                                                                                                className="cursor-pointer"
                                                                                            >
                                                                                                {subMenuItem.menuName}
                                                                                            </Link>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        )}
                                                                    </li>
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                    </ul>

                                                    <div className="social-links desktop">
                                                        <ul className="clearfix list-wrap">
                                                            {socialData.map((item, id) => {
                                                                return (
                                                                    item.isActive === true && item.showInHeader === true &&
                                                                    <li key={id}>
                                                                        <Link target='_blank' to={item.url}>
                                                                            <i className={item.icon} />
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                                <div className={`mobile-menu ${isMenuOpen ? 'show' : ''}`}>
                                    <nav className="menu-box">
                                        <div className="close-btn" onClick={toggleMenu}>
                                            <i className="fas fa-times" />
                                        </div>
                                        <div className="nav-logo" onClick={toggleMenu}>
                                            <Link to="/">
                                                <img loading="auto" src={ImageLink(settingData.logo)} alt='logo_img' />
                                            </Link>
                                        </div>
                                        <div className="menu-outer">
                                            <ul className="navigation">
                                                {menuData.map((item, id) => (
                                                    <li key={id} className={`${item.subMenus.length > 0 ? 'menu-item-has-children' : ''}`}
                                                        onClick={() => !item.subMenus.length > 0 && toggleMenu()}
                                                    >
                                                        <Link to={!item.subMenus.length > 0 && `/${item.menuUrl}`}>
                                                            {item.menuName}
                                                            {item.subMenus.length > 0 && <div className="dropdown-btn open" onClick={() => {
                                                                toggleSubmenu(id)
                                                            }}>
                                                                {openSubmenus[id] ? <span className="fas fa-angle-down" /> : <span className="fas fa-angle-up" />}
                                                            </div>}
                                                        </Link>
                                                        {item.subMenus.length > 0 && (
                                                            <ul className={`sub-menu ${openSubmenus[id] ? 'open' : ''}`} style={{ display: openSubmenus[id] ? 'block' : 'none' }}>
                                                                {item.subMenus.map((subMenuItem, subIndex) => (
                                                                    <li key={subIndex} onClick={() => toggleMenu()}>
                                                                        <Link
                                                                            to={`/${item.menuUrl}/${subMenuItem.menuUrl}`}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            {subMenuItem.menuName}</Link>

                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="social-links">
                                            <ul className="clearfix list-wrap">
                                                {socialData.map((item, id) => {
                                                    return (
                                                        item.isActive === true && item.showInHeader === true &&
                                                        <li key={id}>
                                                            <Link target='_blank' to={item.url}>
                                                                <i className={item.icon} />
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className="menu-backdrop" onClick={toggleMenu} />
                            </div>
                        </div>
                    </div>
                </div >
            </header >
        </>
    )
}

export default Header