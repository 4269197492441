import React from 'react';

const ScrollToTop = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" 
        });
    };

    return (
        <div>
            <button className="scroll-top scroll-to-target" data-target="html" onClick={scrollToTop}>
                <i className="fas fa-angle-up" />
            </button>
        </div>
    );
};

export default ScrollToTop;
