import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/Footer';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import Scroll from './components/Scroll';
import SocialMediaBtn from './HomeComponent/SocialMediaBtn';
import Loader from './components/Loader';
import { getSettings } from './services/getSettings';
import axiosInstance from './axiosInterceptor';
import { getBranch } from './services/getBranch';
import { getBrand } from './services/getBrand';
import Error from './components/Error';
// import NotificationPopup from './components/NotificationPopup';

// Lazy loading components
const Home = React.lazy(() => import('./pages/Home'));
const AboutUs = React.lazy(() => import('./pages/AboutUs'));
const Brands = React.lazy(() => import('./pages/Brands'));
const Infrastructure = React.lazy(() => import('./pages/Infrastructure'));
const OurManagement = React.lazy(() => import('./pages/OurManagement'));
const Investors = React.lazy(() => import('./pages/Investors'));
const Contacts = React.lazy(() => import('./pages/Contacts'));
const Collection = React.lazy(() => import('./pages/Collection'));
const Product = React.lazy(() => import('./pages/Product'));
const InvestorsContact = React.lazy(() => import('./pages/InvestorsContact'));

function App() {
  const [socialData, setSocialData] = useState([]);
  const [settingData, setSettingData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const fetchData = async () => {
    const response = await getSettings();
    setSettingData(response.data);
  };

  // Function to fetch social media data
  const socialMediafetchData = async () => {
    try {
      const response = await axiosInstance.get(`socialMedia/getSocialMedia`);
      const data = response?.data.data;
      setSocialData(data);
    } catch (error) {
      console.error("Error fetching social media data:", error);
    }
  };

  const fetchBranchData = async () => {
    const response = await getBranch();
    setBranchData(response.data);
  };

  useEffect(() => {

    const fetchBrandData = async () => {
      const response = await getBrand();
      setBrandData(response.data);
    };

    socialMediafetchData();
    fetchBranchData();
    fetchBrandData();
    fetchData();
  }, []);

  return (
    <Router>
      <Scroll />
      <ScrollToTop />
      <Header socialData={socialData} settingData={settingData} />
      <Suspense fallback={<div><Loader /></div>}>
        <Routes>
          <Route path="/" element={<Home settingData={settingData} brandData={brandData} branchData={branchData} />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/management/:slug" element={<OurManagement />} />
          <Route path="/investors/:slug" element={<Investors />} />
          <Route path="/contacts" element={<Contacts settingData={settingData} branchData={branchData} />} />
          <Route path="/collection" element={<Collection />} />
          <Route path="/brands/:slug" element={<Product />} />
          <Route path="/investors-contact" element={<InvestorsContact />} />
          <Route exact path="*" element={<Error />} />
        </Routes>
      </Suspense>
      <ToastContainer autoClose={1000} />
      <SocialMediaBtn socialData={socialData} settingData={settingData} />
      <Footer socialData={socialData} settingData={settingData} branchData={branchData} />
    </Router>
  );
}

export default App;
