import React from 'react'
import { Link } from 'react-router-dom';

const Footer = ({ settingData, socialData, branchData }) => {

    const formatAddress = (address,) => {
        return { __html: address?.replace(/\r?\n/g, '<br>') };
    }
    const formatContact = (contactNumber) => {
        return { __html: contactNumber?.replace(/\r?\n/g, '<br>') };
    };

    return (
        <><footer>
            <div className="footer-area footer-bg">
                <div className="container">
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 col-sm-7">
                                <div className="footer-widget text-center">
                                    <h3 className="fw-title">FOLLOW US</h3>
                                    <div className="footer-social">
                                        <ul className="list-wrap">
                                            {socialData?.map((item, id) => {
                                                return (
                                                    item.isActive === true && item.showInFooter === true &&
                                                    <li key={id}>
                                                        <Link target='_blank' to={item.url}>
                                                            <i className={item.icon} />
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-5 col-sm-7">
                                <div className="footer-widget text-center">
                                    <h3 className="fw-title">CORPORATE OFFICE</h3>
                                    <div className="footer-link">
                                        <p dangerouslySetInnerHTML={formatAddress(settingData.headOffice)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 col-sm-5">
                                <div className="footer-widget text-center">
                                    <h3 className="fw-title">CALL US</h3>
                                    <div className="footer-link">
                                        <p dangerouslySetInnerHTML={formatContact(settingData.contactNumber)} />
                                        <p>
                                            <Link to={`mailto:${settingData.contactEmail}`}> {settingData.contactEmail}</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="footer-link-h">
                                    <ul>
                                        {branchData?.map((brand, id) => {
                                            return (
                                                <li key={id}>
                                                    <span className='brand_city'>{brand.city}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="copyright-text text-center">
                                    <p>
                                        ©{new Date().getFullYear()} Ashapuri Gold Ornament Limited{" "}
                                        <span className="copyright-space">|</span> Website developed by
                                        :{" "}
                                        <Link to="https://www.setblue.com/" target="_blank">
                                            Setblue.com
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer