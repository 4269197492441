import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const navigation = useNavigate();

    useEffect(() => {
        const timeout = setTimeout(() => {
            navigation('/');
        }, 3000);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <>
            <div className="col-lg-12 pl--30 mt--60 mb--60 pl_sm--0 border-signup">
                <div className="">
                    <div className='head-section'>
                        <h1 className="title text-center mb--10 mt-4 error-size">404</h1>
                    </div>
                    <div className='text'>
                        <h2 className="text-center"> <span className="text-danger">Opps!</span> Page not found.</h2>
                    </div>
                    <div className='text-center'>
                        <h3>The page you're looking for doesn't exist.</h3>
                        <h3>Redirecting you to our homepage.</h3>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Error;