import React from "react";

const Loader = () => {
    return (
        <div className="text-center">
            <div className="rotate-ring-container">
                <div className="rotate-ring">
                    <img src="/assets/img/favicon.png" alt="Loading" />
                </div>
            </div>
        </div>
    );
};

export default Loader;
