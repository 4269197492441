import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../App.css";

const SocialMediaBtn = ({ socialData, settingData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const whatsappLink = `https://api.whatsapp.com/send?phone=${settingData.whatsAppNumber}`;
  return (
    <>
      <div className="floating-action-button">
        {isExpanded && (
          <div className="fab-options">
            {socialData.map((item, id) => {
              return (
                <div className="fab-option" key={id}>
                  <Link to={item.url} target='_blank'><i className={item.icon}></i></Link>
                </div>
              )
            })}
          </div>
        )}
        <button
          className={`fab-button ${isExpanded ? 'rotate-48' : ''}`}
          onClick={toggleExpanded}
        >
          <i className="fas fa-plus"></i>
        </button>
      </div>
      <Link to={whatsappLink} target="_blank" >
        <button className={`whatsapp-button `}>
          <i className="fab fa-whatsapp text-white" />
        </button></Link>
    </>
  );
};

export default SocialMediaBtn;
